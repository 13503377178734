import React from "react"
import Navbar from "./navbar"

export default function Layout(props) {
  const classes = props.className || ""
  const newProps = delete props.className

  return (
    <div {...newProps} className={`layout ${classes}`}>
      <Navbar />
      <div className="main-wrapper">
        {props.children}
      </div>
    </div>
  )
}
