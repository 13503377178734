import './src/sass/main.scss'

// import "bootstrap/dist/js/bootstrap.min.js";
// import "@popperjs/core/dist/umd/popper.min.js";
// import "@fontsource/poppins"; // Defaults to weight 400
// import "@fontsource/source-sans-pro"; // Defaults to weight 400

import React from "react"

import Layout from "./src/components/layout"

export function wrapPageElement({ element, props }) {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>
}
