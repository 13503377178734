import React, { useState } from 'react'

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (
    <nav className="navbar d-flex">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="navbar-brand">
          <a href="/">Home</a>
        </div>
        <button id="burger-wrapper" className={`sidemenu-trigger ${isMenuOpen && "menu-open"}`} onClick={() => {
          setIsMenuOpen(!isMenuOpen)
        }}>
          <div id="menu-burger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
    </nav>
  )
}

export default Navbar